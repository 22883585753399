@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@300;500;600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --main-gray: rgb(233, 233, 233);
  --links-not-active: #656565;
  --color-invalid-100proc: rgb(232, 28, 36);
  --color-invalid-faded: rgb(232, 28, 36, 0.1);
  --color-invalid-button: rgb(232, 28, 36, 0.7);
  --color-green-100proc: rgb(154, 172, 19);
  --color-green-70proc: rgb(154, 172, 19, 0.7);
  --placeholder-color: #d6d6d6;
  --transition-speed: 1800ms;
}

#root {
  height: 100%;
}

#root .to-be-moved {
  height: 100vh;
}

/* iframe {
  object-fit: fill;
} */

.video-container {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  /* height: 100%; */
  margin: 0 auto;
  /* 
  min-height: 480px;
  height: 100%;

  text-align: center;
  */
}

/* position: relative;
  width: 100%;
  padding-top: calc(427 / 1126 * 100%); */

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--placeholder-color);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--placeholder-color);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--placeholder-color);
}

body {
  /* background: url("./images/body-bg.png") no-repeat top center; */
  margin: 0;
  position: relative;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  letter-spacing: 1px;
  font-size: 13px;
  color: #555;
  height: 100%;
  overflow: scroll;
}

.bigger-text {
  font-size: 14px;
  font-weight: 500;
  /* background-color: red; */
  padding: 0 4rem 0 4rem;
}

.body-height-to-move-footer {
  height: 100vh;
}

body .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
body .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
}

/* body .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
body .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
} */
a {
  font-weight: 900;
  color: var(--links-not-active);
}
a:hover,
li.active a {
  color: #000;
  font-weight: 900;
}
h1,
h2,
h3,
h4,
.nav a {
  text-transform: uppercase;
  color: var(--links-not-active);
  margin-top: 0;
  margin-bottom: 0;
}
h2 {
  font-size: 26px;
  font-weight: 700;
}
h3,
#parent .desc h3 {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.2px;
  margin-bottom: 0;
}
h4,
#parent .desc h4 {
  font-weight: 300;
  font-size: 11px;
  letter-spacing: -0.2px;
}
#portfolio img {
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
}
.navbar-nav {
  float: none;
  text-align: center;
  padding: 20px 0;
}
.navbar-nav > li {
  float: none;
  display: inline-block;
}
.navbar-nav > li a {
  padding: 14px 18px 0 20px;
}
.nav > li > a:hover,
.nav > li > a:focus {
  background-color: inherit;
}
.navbar-collapse {
  background: url("./images/navbar-bg.png") no-repeat center top #fff;
}
.box {
  overflow: hidden;
}
.box-outer {
  margin-bottom: 44px;
}
.box img {
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.box:hover img {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  transform: scale(1.04) rotate(0deg);
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.gray img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.6;

  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.more {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0 22px;
  -webkit-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.box:hover .more {
  display: block;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
div.project {
  text-align: center;
  vertical-align: bottom;
  background: url("./images/img-hover.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  height: 100%;
  left: 22px;
  right: 22px;
}
.desc {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding-bottom: 18px;
}
.link {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#galleryCarousel {
  position: relative;
  width: 100%;
  padding-top: calc(625 / 1126 * 100%);
}

.galleryCarouselFourOFour {
  position: relative;
  width: 100%;
  padding-top: calc(427 / 1126 * 100%);
}

.text-fourOFour {
  margin-bottom: 4rem;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: -2.5rem;
}

.galleryCarouselFourOFour .carousel-inner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: calc(427 / 1126 * 100%);
}

#galleryCarousel .carousel-inner {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: calc(625 / 1126 * 100%);
}

#galleryCarousel .item {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  padding-top: calc(625 / 1126 * 100%);
}

.galleryCarouselFourOFour .itemFourOFour {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  /* background-color: green; */
  padding-top: calc(427 / 1126 * 100%);
}

.itemFourOFour > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

/* #galleryCarousel .item > img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
} */

#galleryCarousel .item > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#galleryCarouselFourOFour .item > img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

#galleryCarousel .item .carousel-img-transform-in {
  opacity: 0;
  animation: fadein 5000ms linear 2500ms forwards;
}

#galleryCarousel .item .carousel-img-transform-out {
  opacity: 0;
  animation: fadeout 5800ms linear forwards;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.footer {
  clip-path: polygon(0 0, 100% 62%, 100% 100%, 0% 100%);
  background-color: #e9e9e9;
  margin-top: -120px;
  padding-top: 150px;
  padding-bottom: 8px;
  font-weight: 400;
  position: relative;
  z-index: -1;
}

.logo-bottom {
  margin-bottom: 30px;
  margin-top: 20px;
}
.logo-top {
  margin-bottom: 15px;
}
.logo-top img {
  margin-top: 20px;
}

.lang a {
  display: inline-block;
  padding: 14px 12px 11px 13px;
  background: #fff;
  text-decoration: none;
}
.menu-top {
  margin-top: 5px;
}

.menu-main-links {
  visibility: visible;
}
.top-nav {
  text-align: right;
}
.top-nav li {
  float: right;
  padding: 0;
}
.top-nav li a {
  padding: 14px 0px 2px 0px;
  margin-bottom: 9px;
  margin-left: 24px;
}

.navbar-toggle {
  background: #555;
  border-radius: 0;
  margin-top: 0;
  margin-right: 0;
}

.navbar-toggle .icon-bar {
  background: #fff;
}
.navbar-header {
  float: right;
  z-index: 120;
}
.navbar-hamburger {
  margin-right: -20px;
}
#the-sticky-div {
  position: relative;
  text-align: center;
  /* top: initial; */
}
#the-sticky-div.sticky {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  padding-top: 20px;
  background-color: #fff;
}
.img-gallery img {
  display: block;
  max-width: 100%;
  margin-bottom: 44px;
}
.img-gallery .big-img {
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.img-center img {
  margin-left: auto;
  margin-right: auto;
}
#project-site {
  text-align: center;
}
#project-site h2 {
  margin-bottom: 14px;
}

#project-site h3 {
  font-size: 17px;
  margin-bottom: 4px;
}
#project-site h4 {
  font-size: 13px;
  font-weight: 700;
}

#project-site p {
  font-size: 11px;
  color: #b4b4b4;
  margin: 0;
}

.project-desc {
  margin-top: 74px;
  margin-bottom: 42px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-right: 22px;
  padding-left: 22px;
}
.modal-panorama {
  width: 1140px;
  margin-top: 12%;
}
.modal-panorama .modal-body {
  padding: 0;
}
.modal-panorama .close {
  opacity: 1;
}
.modal-panorama .modal-content {
  border-radius: 0;
  padding: 0;
  border: 3px solid #555;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-panorama .modal-header {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 20;
  background: #fff;
  padding: 12px 16px;
}
#panorama-in-1,
#panorama-in-2,
#panorama-in-3,
#panorama-in-4,
#panorama-in-5,
#panorama-in-6,
#panorama-in-7,
#panorama-in-8 {
  width: 100%;
  height: 500px;
}

#my-bg-gray {
  /* display: none; */
  clip-path: polygon(0 0, 100% 0, 100% 62%, 0% 100%);
  background-color: #e9e9e9;
  width: 100%;
  height: 300px;
  position: absolute;
  z-index: -1;
  top: 0;
}
#my-bg-dark-gray {
  /* display: none; */
  clip-path: polygon(0 0, 100% 0, 100% 62%, 0% 100%);
  background-color: #555;
  width: 100%;
  height: 297px;
  position: absolute;
  z-index: -1;
  top: 0;
}
#my-bg-white {
  /* display: none; */
  clip-path: polygon(0 0, 100% 0, 100% 62%, 0% 100%);
  background-color: #fff;
  width: 100%;
  height: 295px;
  position: absolute;
  z-index: -1;
  top: 0;
}

.main-nav-link {
  text-transform: uppercase;
  color: #555;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 900;
  cursor: pointer;
  list-style: none;
  font-size: 13;
  position: relative;
}
.main-mobile-nav-link {
  font-size: 20px !important;
  /* color: red; */
}
.main-nav-link:hover {
  color: #000;
}
.main-nav-link-active {
  color: #000;
  cursor: not-allowed;
}
.main-mobile-nav-link-active {
  font-size: 20px !important;
  /* margin-left: auto;
  float: right; */
}
.cat .main-nav-link-active {
  color: #000;
  cursor: not-allowed;
}
/* .main-nav-link-active::after {
  bottom: 0;
  content: url("../src/images/navlinks-active.png");
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  transform: scale(0.5);
  width: 50px;
  max-width: 10px;
  opacity: 0.08;
} */

/* .main-nav-link-active::after {
  background: url("../src/images/navlinks-active.png");
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transform: translate(-50%, 0);
  width: 50px;
  opacity: 0.08;
} */

.button {
  display: inline-block;
  background: #fff;
  text-decoration: none;
  font-weight: 900;
  color: var(--links-not-active);
  outline: none;
  border: none;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.button--default {
  padding: 14px 12px 11px 13px;
}
.button:hover {
  color: #000;
}

.button-as-div-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.slide-transition-enter {
  opacity: 0;
}
.slide-transition-enter-active {
  opacity: 1;
  transition: opacity 4000ms;
  /* transition-delay: 600ms; */
}
.slide-transition-exit {
  opacity: 1;
}
.slide-transition-exit-active {
  opacity: 0;
  transition: opacity 4000ms;
}

.see-more-button {
  text-align: center;
  border: var(--main-gray) solid 1px;
  width: 100%;
  margin-bottom: 26px;
}

/* modal */
.backdrop-mine {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(233, 233, 233, 0.75);
  z-index: 10;
}
.modal--mine {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.02);
  /* border-radius: 8px; */
}

.modal__header--mine {
  width: 100%;
  background: #fff;
  color: var(--main-gray);
  padding: 2rem 0 1rem 0;
  border-bottom: 3px dotted var(--main-gray);
  font-weight: 900;
  text-align: center;
}

.modal__header--mine h2 {
  margin: 0.5rem;
}

.modal-mine__content {
  padding: 2rem 0 1rem 0;
  font-size: 1.8rem;
}

.modal__footer--mine {
  padding: 1rem 0.5rem;
}

.warning-color {
  color: var(--color-invalid-button);
}
.warning-color:hover {
  color: var(--color-invalid-100proc);
}

.modal-header-mine__panorama {
  display: none;
}

.modal-header-mine__show-header {
  display: block;
}

.modal-header-mine__show-header-login {
  display: block;
  border-bottom: none;
  padding: 2rem 0 0 0;
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

.py-admin {
  padding-top: 90px;
}

/* form */
form {
  margin: 0 0 12px 0;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato", Georgia, serif;
}

.form {
  margin: 0 0 12px 0;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato", Georgia, serif;
}

.details {
  font-size: 1.3rem;
  font-weight: 900;
  display: block;
}

.form-row {
  display: flex;
  justify-content: space-between;
  /* flex: 1; */
  padding-bottom: 1rem;
}

.align-to-right {
  justify-content: right;
  padding-right: 20px;
}

.flex-changer {
  align-items: center;
}

.project-details {
  width: 100%;
  padding: 0 3rem 1.5rem 1rem;
}

.details-title {
  font-size: 2rem;
  font-weight: 900;
  display: block;
}

.input-box-image {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.drop-zone {
  width: 100%;
  height: 80px;
  margin-left: 12px;
  /* margin-right: 12px; */
  border: 1px dotted var(--main-gray);
  flex: 1;
  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    var(--main-gray) 10px,
    var(--main-gray) 11px
  );
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-gray);
}

.drop-zone:hover {
  border: 2px dotted var(--links-not-active);
  color: var(--links-not-active);
}

.drop-zone-middle-zone {
  color: inherit;
}

.drop-zone-text {
  background: #fff;
  /* color: var(--main-gray); */
  color: inherit;
  padding: 1.5rem 10rem 1.5rem 10rem;
  font-size: 20px;
  margin-top: 12px;
  line-height: 14px;
}

.small-text {
  font-size: 12px;
}

/* .drop-zone-text:hover {
  color: var(--links-not-active);
} */

.drop-zone-active {
  border: 2px dotted var(--links-not-active);
}

.image-button {
  align-self: center;
}

.image-upload-padding {
  /* background-color: red; */
  /* padding-left: 12px; */
}

.input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 3px;
  border: 1px solid #999;
  padding-left: 15px;
  font-size: 1.3rem;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  border: 2px solid #eee;
  background: #f9f9f9;
}

.input-box textarea {
  height: 190px;
  width: 100%;
  outline: none;
  border-radius: 3px;
  border: 1px solid #999;
  padding-left: 15px;
  font-size: 1.3rem;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  border: 2px solid #eee;
  background: #f9f9f9;
}

.no-background {
  background: none;
}

.input-box input:focus {
  border: 2px solid rgb(61, 61, 61);
}

.separator {
  margin: 2rem 3rem 2.5rem 1.3rem;
  border-bottom: 3px dotted #ccc;
}

#portfolio .project-types {
  font-size: 1.2rem;
  margin: 2rem 3rem 2.5rem 1.3rem;
}

.input-box-checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  transition: all 0.3s ease;
}

.input-box-radios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  transition: all 0.3s ease;
}

/* checkbox styling */
.checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkbox__input {
  display: none;
}

.checkbox__box {
  width: 1.25em;
  height: 1.25em;
  border: 2px solid var(--main-gray);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  flex-shrink: 0;
  transition: background 0.15s, border-color 0.15s;
}

.checkbox__box::after {
  content: "\2714";
  color: white;
  transform: scale(0);
  transition: transform 0.15s;
}

.checkbox__input:checked + .checkbox__box {
  background: var(--links-not-active);
  border-color: var(--links-not-active);
}

.checkbox__input:checked + .checkbox__box::after {
  transform: scale(1);
}

.p-invalid {
  /* background-color: var(--color-invalid-100proc); */
  color: var(--color-invalid-100proc);
  font-size: 1.3rem;
  font-weight: 900;
  margin-top: 2px;
}

#login .input-box input {
  width: 40%;
}

.center {
  text-align: center;
}

.input-box .input-invalid {
  border: 1px solid var(--color-invalid-100proc);
  background-color: var(--color-invalid-faded);
}

.thumbnail-error {
  border: 1px solid var(--color-invalid-100proc) !important;
  background-color: var(--color-invalid-faded);
}

.image-error {
  /* fill: var(--color-invalid-faded); */
  background-color: var(--color-invalid-faded);
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  -moz-opacity: 70%;
  -webkit-opacity: 70%;
  z-index: 2;
}

#portfolio .btn-portfolio {
  outline: 2px solid #eee;
  background-size: 100% 50%, 100% 50%;
  background-repeat: no-repeat, no-repeat;
  background-position: center top, center bottom;
  /* color: var(--color-green-70proc); */
}

#portfolio .btn-portfolio:hover {
  color: #000;
  outline: 2px solid #ddd;
}

#portfolio .create-project-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  margin-top: 32px;
  margin-right: 12px;
}

#portfolio ol {
  margin: 0;
  padding: 0 12px 0 12px;
  list-style: none;
  counter-reset: ordered-list;
}

#portfolio ol li {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-top: 12px;
  border-bottom: #ddd dotted 2px;
  height: 100px;
}
#portfolio ol li::before {
  content: counter(ordered-list);
  counter-increment: ordered-list;
  font-weight: 900;
  font-size: 2rem;
  margin-right: 1.5rem;
  color: #777;
}
#portfolio ol li:first-child {
  flex: 0 0 20px;
}

#portfolio ol li:nth-child(even) {
  background-color: rgb(252, 252, 252);
}

#portfolio ol li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

#portfolio ol li .project-text {
  /* border-left: #ddd solid 2px; */
  padding-left: 12px;
  /* flex: 1; */
}

#portfolio .btn-delete {
  margin-left: 12px;
  margin-right: 12px;
}

#portfolio .thumbnail-admin img {
  margin-left: 12px;
  width: 100px;
  height: 80px;
  border-left: #ddd solid 2px;
  padding-left: 12px;
}

.thumbnail-admin-form {
  width: 100px;
  height: 80px;
  border: var(--main-gray) solid 1px;
}

#portfolio .buttons-edit-delete {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-left: 12px;
  border-right: #ddd solid 2px;
  align-items: center;
}

.list-name {
  margin-left: 36px;
  padding-left: 12px;
  border-left: var(--links-not-active) solid 2px;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 4px;
}

.footer-bottom {
  /* position: sticky; */
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0; */
  /*  to */
  width: 100%;
  position: absolute;
  bottom: 0;
}

/* html, body, .container {
        height: 100%;
    }
    .container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .header, .footer {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }
    .body {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -moz-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
    } */

.title-new-project {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 0 2rem 0;
}

.title-new-project-between {
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 3rem 0 0 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}

.div-center {
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
}

.div-center-no-flex {
  margin-bottom: 6rem;
}

.div-center-no-margin {
  display: flex;
  justify-content: center;
}

.div-center-no-py {
  display: flex;
  justify-content: center;
}

.py-bottom {
  margin-bottom: 6rem;
}

.py-bottom2_5 {
  margin-bottom: 2.5rem;
}

.section-my-bottom {
  padding: 0 0 4rem 0;
}
.small-my {
  padding: 2.5rem 0 4rem 0;
}
.no-py-top {
  padding-top: 0;
}

.separator {
  margin: 2rem 3rem 0 1.3rem;
  border-bottom: 2px solid #ccc;
  width: 50%;
}

.my-bottom {
  margin-bottom: 6rem;
}

.my-top {
  margin-top: 5rem;
}

/* form progress bar */
#progressbar {
  /* margin-bottom: 15px; */
  padding-top: 2px;
  margin-top: 45px;
  overflow: hidden;
  counter-reset: step;
  width: 100%;
  text-align: center;
}
#progressbar li {
  list-style-type: none;
  color: var(--main-gray);
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 900;
  width: 33%;
  float: left;
  position: relative;
}
#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 20px;
  line-height: 20px;
  display: block;
  font-size: 10px;
  color: var(--main-gray);
  background: white;
  margin: 0 auto 5px auto;
}
/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: var(--main-gray);
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1;
}
#progressbar li:first-child:after {
  content: none;
}
/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  /* background: #7b1fa2; */
  color: var(--links-not-active);
  font-weight: 600;
  outline: 2px solid #eee;
}
#progressbar li.active {
  color: var(--links-not-active);
}

/* checkbox */
#portfolio .project-types {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#portfolio .project-types > * {
  box-sizing: border-box;
}

#portfolio .checbox-container {
  display: flex;
  align-items: center;
}

#portfolio .checbox-container label {
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
}

#portfolio .checbox-container input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

#portfolio .checbox-container label::before {
  content: "";
  width: 1.1em;
  height: 1.1em;
  border: 2px solid var(--main-gray);
  margin-top: 2px;
  margin-right: 0.2em;
}

#portfolio .checbox-container label:hover::before,
#portfolio .checbox-container input[type="checkbox"]:hover + label::before {
  background: var(--main-gray);
}

#portfolio .checbox-container input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 4px #999;
}

#portfolio .checbox-container input[type="checkbox"]:checked + label::before {
  content: "\002714";
  background: #f9f9f9;
  border: 0.15em solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.little-down {
  margin-top: 8px;
}

.btn-lighter {
  color: var(--main-gray);
}
.btn-lighter-checked {
  color: #000;
}

.no-form-button {
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato", Georgia, serif;
}

.input-box .width-shortener {
  width: 250px;
}

.input-box .input-wider {
  width: 100%;
}

.image-set {
  background-color: rgb(252, 252, 252);
  border-radius: 2px;
  padding-left: 25px;
  padding-top: 10px;
  border-left: 2px solid var(--links-not-active);
  border-right: 2px solid var(--links-not-active);
  /* margin-bottom: 50px; */
}

.radio-label {
  padding-left: 5px;
}

.radio-item {
  margin-left: 25px;
}

input[type="radio"] {
  filter: grayscale(100%) contrast(200%);
}

.modal-content {
  margin: 0 0 12px 0;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato", Georgia, serif;
}

@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 2s ease-in-out;
    -o-transition: -o-transform 2s ease-in-out;
    transition: transform 2s ease-in-out;
  }
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.menu-wrap {
  visibility: hidden;
}

.red-bg {
  background-color: red;
}

@media (max-width: 768px) {
  .menu-main-links {
    visibility: hidden;
  }

  /* mineHamburger - start  */

  .hamburger-details {
    margin-top: 5px;
    /* z-index: 120; */
  }
  .menu-wrap {
    visibility: visible;
    position: relative;
    /* border: #555 solid 2px; */
    /* border-radius: 20px; */
    margin: 0;
    width: 45px;
    height: 45px;
  }
  .menu-wrap .toggler {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    height: 40px;
    opacity: 0;
  }

  .menu-wrap .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 45px;
    height: 45px;
    padding: 1rem;
    /* border: var(--main-gray) solid 1px; */
    /* background: #555; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* Hamburger Line */
  .menu-wrap .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
  }

  /* Hamburger Lines - Top & Bottom */
  .menu-wrap .hamburger > div::before,
  .menu-wrap .hamburger > div::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -7px;
    width: 100%;
    height: 2px;
    background: inherit;
  }
  .menu-wrap .hamburger > div::after {
    top: 7px;
  }

  /* animation */
  .menu-wrap .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
  }
  .menu-wrap .toggler:checked + .hamburger > div:before,
  .menu-wrap .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
  }
  .menu-wrap .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
  }

  .hamburger-visible {
    padding: 1rem 0;
  }
  /* mineHamburger - end  */

  .text-fourOFour {
    padding-top: 1.5rem;
    font-size: 1.3rem;
  }
  .navbar-nav {
    padding: 0;
    margin: 0;
  }
  .navbar-nav > li a {
    padding: 5px;
  }
  #the-sticky-div h2 {
    padding-left: 44px;
  }
  .more .desc {
    display: none;
  }
  div.project {
    background: none;
  }
  .box-outer {
    margin-bottom: 22px;
  }
  #the-sticky-div.sticky {
    padding-top: 10px;
  }
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    padding-left: 11px;
    padding-right: 11px;
  }
  .row {
    margin-left: -11px;
    margin-right: -11px;
  }
  .img-gallery img {
    margin-bottom: 22px;
  }
  .img-gallery .big-img {
    max-width: 100%;
  }

  #login .input-box input {
    width: 100%;
  }
}

.image-src {
  font-size: 14px;
  color: var(--links-not-active);
  margin-left: 12px;
}

/* react-date-picker */
.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
  /* me */
  height: 45px;
  /* width: 100%; */
  outline: none;
  border-radius: 3px;
  border: 1px solid #999;
  padding-left: 15px;
  font-size: 1.3rem;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  border: 2px solid #eee;
  background: #f9f9f9;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.input-box .react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #000;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
  stroke: var(--links-not-active);
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  /* me */
  outline: none;
  border-radius: 3px;
  border: 1px solid #999;
  font-size: 1.3rem;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  border: 2px solid #eee;
  background: #f9f9f9;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

.react-calendar__tile--now {
  background: var(--main-gray);
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
}

.react-date-picker__inputGroup__input:invalid {
  background: var(--color-invalid-faded);
}

/* CSS transition group */
/* appear - on page load */
.carousel-appear {
  opacity: 0;
  z-index: 1;
}
.carousel-appear.carousel-appear-active {
  opacity: 1;
  transition: opacity var(--transition-speed) linear;
}

/* enter */
.carousel-enter {
  opacity: 0;
  z-index: 1;
}
.carousel-enter.carousel-enter-active {
  opacity: 1;
  transition: opacity var(--transition-speed) linear;
}

/* exit */
.carousel-exit {
  opacity: 1;
}
.carousel-exit.carousel-exit-active {
  opacity: 0;
  transition: opacity var(--transition-speed) linear;
}
.carousel-exit-done {
  opacity: 0;
}

#footer-move-trigger {
  position: relative;
  height: 1px;
  width: 100%;
  /* background-color: red; */
}

#pagination-trigger {
  position: relative;
  height: 1px;
  width: 100%;
  /* background-color: blue; */
}

/* .last-div-fill {
  position: absolute;
  width: 100%;
  background-color: #e9e9e9;
} */

.footer-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 100%; */
}
.footer-bottom-fill {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
}

.overall-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

#footer-fill {
  background-color: #e9e9e9;
  flex-grow: 1;
  height: 100%;
}

.portfolio-padding {
  padding-bottom: 24px;
}

.mobile-menu {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: white;
}

.mobile-ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 8px;
  height: 100vh;
  margin-right: 39px;
  list-style: none;
  text-align: right !important;
}

/* .mobile-li {
  list-style: none;
} */

.hamburger-z-index {
  z-index: 12 !important;
}
